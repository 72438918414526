<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-follow-detail-wrap"
  >
    <div
      class="w-follow-detail-main"
      v-if="hasPermission"
    >
      <div class="w-detail-head">
        <div class="w-detail-info">
          <div class="w-info-name">目标品牌： <span
              class="cursor"
              @click="toDetails(brandDetails)"
            >{{brandDetails.brandName || '-'}}</span>
            <div class="follow-status"> <span
                class="dots"
                :style="{'background': brandDetails.color}"
              ></span> {{brandDetails.taskState}} </div>
          </div>
          <div class="w-info-msg">
            <!-- <span class="w-title">创建时间：</span>
            <span class="w-text">{{brandDetails.createTime ? brandDetails.createTime: '-'}}</span>
            <span class="w-title">跟进人员：</span>
            <span class="w-text">{{brandDetails.followUserName ? brandDetails.followUserName: '-'}}</span>
            <span class="w-title">最近跟进时间：</span>
            <span class="w-text">{{brandDetails.lastFollowTime ? brandDetails.lastFollowTime: '-'}}</span> -->
            <span class="w-title">品牌业态：</span>
            <span class="w-text">{{brandDetails.typeNames ||'-'}}</span>
            <span class="w-title">品牌级次：</span>
            <span class="w-text">{{brandDetails.brandClass || '-'}}</span>
            <!-- <span class="w-title">意向落位铺位数：</span>
            <span class="w-text">{{brandDetails.intentionNumber || '-'}}</span> -->
          </div>
          <div class="w-info-btns">
            <div class="states-wrap">
            </div>
            <span
              v-if="hasPermissionFollow"
              :class="[brandDetails.taskState === '已签约' || isDisabled
              ? 'gray': '']"
              @click="editFollowStatus(invesmtentItem)"
              :title="isDisabled ? '当前任务未发布或存在未完结的审批单，无法操作': ''"
            >修改跟进进度</span>
            <span
              v-else
              :class="[brandDetails.taskState === '已签约'? 'gray': '']"
              @click="hasnotPermission"
            >修改跟进进度</span>
            <span
              :class="[brandDetails.taskState === '已签约' ||
              (isDisabled) ? 'gray': '']"
              @click="editFollowFunc()"
              :title="isDisabled ? '当前任务未发布或存在未完结的审批单，无法操作': ''"
            >修改跟进人员</span>
            <span
              @click="addContact()"
              v-if="brandDetails.taskState !== '已签约'"
              :class="[isDisabled ? 'gray': '']"
              :title="isDisabled ? '当前任务未发布或存在未完结的审批单，无法操作': ''"
            >添加品牌联系人</span>
            <span
              :class="[isDisabled ? 'gray': '']"
              :title="isDisabled ? '当前任务未发布或存在未完结的审批单，无法操作': ''"
              v-if="hasPermissionEditplantime"
              @click="handleTimeDialog(0)"
            >修改计划时间</span>
            <span
              :class="[isDisabled ? 'gray': '']"
              :title="isDisabled ? '当前任务未发布或存在未完结的审批单，无法操作': ''"
              v-if="hasPermissionEditfinishtime"
              @click="handleTimeDialog(1)"
            >修改完成时间</span>
            <span
              @click="deleteInvestmentClick()"
              v-if="brandDetails.taskState !== '已签约'"
            >删除跟进任务</span>
          </div>
        </div>
      </div>
      <div class="w-task-detail-wrap">
        <div class="follow-detail-bg">
          <div class="w-task-title">
            <h3 class="w-title-gradients">品牌跟进详情</h3>
          </div>
          <div class="w-task-table">
            <table>
              <tr>
                <td>项目信息</td>
                <td>{{brandDetails.projectName}}</td>
                <td>当前跟进人员</td>
                <td>{{brandDetails.followUserName || '-'}}</td>
                <td>最后跟进时间</td>
                <td>{{brandDetails.lastFollowTime || '-'}}</td>
                <td>发布人</td>
                <td>{{brandDetails.createUserName || '-'}}</td>
                <td>创建时间</td>
                <td>{{brandDetails.createTime || '-'}}</td>
              </tr>
              <tr>
                <td>计划开始时间</td>
                <td>{{brandDetails.planStartTime || '-'}}</td>
                <td>计划完成时间</td>
                <td>{{brandDetails.planFinishTime || '-'}}</td>
                <td>实际开始时间</td>
                <td>
                  <template v-if="brandDetails.realityStartTimeStr">
                    <span
                      v-if="brandDetails.checkStartTimeout===0"
                      class="status circle-green"
                    ></span>
                    <span
                      v-else
                      class="status circle-red"
                    ></span>
                  </template>
                  {{brandDetails.realityStartTimeStr || '-'}}
                </td>
                <td>实际完成时间</td>
                <td>
                  <template v-if="brandDetails.checkFinishTimeout">
                    <span
                      v-if="brandDetails.checkFinishTimeout===0"
                      class="status circle-green"
                    ></span>
                    <span
                      v-else
                      class="status circle-red"
                    ></span>
                  </template>
                  {{brandDetails.realityFinishTimeStr || '-'}}
                </td>
                <td>任务编码</td>
                <td>{{brandDetails.id || '-'}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div
          class="intended-berth-list"
          v-if="brandDetails.taskState !== '已签约'"
        >
          <div class="w-task-title">
            <h3 class="w-title-gradients">意向落位铺位</h3>
            <div
              class="intended-berth-mgn"
              @click="berthManage()"
            >
              <span class="icon-font icon-intended-berth"></span>意向铺位管理
            </div>
          </div>
          <div class="intended-table-wrap">
            <table>
              <thead>
                <tr>
                  <th>落位铺位</th>
                  <th>楼宇楼层</th>
                  <th>规划业态</th>
                  <th>招商品牌级次</th>
                  <th>铺位使用状态</th>
                  <th>铺位招商状态</th>
                  <th>租金标准价</th>
                  <th>计租面积</th>
                  <th>招商负责人</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in intentionBerthList"
                  :key="index"
                >
                  <td
                    class="is-first"
                    @click="toInvestmentFollowDetail(item.berthId)"
                  >
                    <span v-if="item.isFirst !== 2">落</span>
                    {{item.berthNo}}
                  </td>
                  <td>{{item.buildingFloor}}</td>
                  <td>{{item.industry1 || '-'}}</td>
                  <td>{{item.brandClass || '-'}}</td>
                  <td>{{item.stateName}}</td>
                  <td>{{item.berthState}}</td>
                  <td>{{item.rentPrice ? item.rentPrice + '元/月/㎡' : '-'}}</td>
                  <td>{{item.useArea + '㎡' || '-'}}</td>
                  <td>{{item.principalName || '-'}}</td>
                  <td>
                    <span
                      class="btns"
                      @click="deleteIntention(item)"
                      v-if="item.isFirst === 2"
                    >删除</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style="margin-top:35px"
              class="details-no-data"
              v-if="intentionBerthList && intentionBerthList.length === 0"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
          </div>
        </div>
        <div class="follow-detail-bg">
          <div
            class="w-task-title"
            v-if="recordList && recordList.length > 0"
          >
            <h3 class="w-title-gradients">跟进记录</h3>
          </div>
          <div class="w-task-content">
            <span class="w-liner"></span>
            <div
              class="w-record-item"
              v-for="(item, index) in recordList"
              :key="index"
            >
              <span class="w-point"></span>
              <div class="w-item-title">
                <span>{{item.followMsg}}
                  <template v-if="item.signedBrandList && item.signedBrandList.length > 0 && item.followState === '已签约'">
                    <i
                      v-for="(list, index) in item.signedBrandList"
                      :key="index"
                      @click="toContractDetail(list)"
                    >{{list.berthNo}}合同详情</i>
                  </template>
                </span>
              </div>
              <div
                v-if="item.linkman"
                class="more-contact"
                @click="toDetails(brandDetails)"
              ><span class="fr liner-bottom pointer">查看更多</span></div>
              <div
                class="w-task-item-table"
                v-if="item.linkman"
              >
                <table>
                  <tr>
                    <td>联系人姓名</td>
                    <td>{{item.linkman.linkName}}</td>
                    <td>所属企业类型</td>
                    <td>{{item.linkman.companyType === 0 ? '直营': '代理'}}</td>
                    <td>联系人企业</td>
                    <td>{{item.linkman.companyName}}</td>
                  </tr>
                  <tr>
                    <td>联系人职位</td>
                    <td>{{item.linkman.linkZhiwei}}</td>
                    <td>联系人手机</td>
                    <td>{{item.linkman.mobile ? item.linkman.mobile: '-'}}{{item.linkman.mobile2 ? '/' + item.linkman.mobile2: ''}}</td>
                    <td>联系人固话</td>
                    <td>{{item.linkman.linkTel==''?'-':item.linkman.linkTel}}{{item.linkman.linkTel2 ? '/' + item.linkman.linkTel2: ''}}</td>
                  </tr>
                  <tr>
                    <td>联系人邮箱</td>
                    <td>{{item.linkman.email || '-'}}</td>
                    <td>负责拓展城市</td>
                    <td>{{item.linkman.linkTuozhancity}}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
                <!-- <table v-if="item.followState === '已签约'"> -->
                <table v-if="false">
                  <tr>
                    <td>项目名称</td>
                    <td>{{item.signedBrand.projectName}}</td>
                    <td>品牌名称</td>
                    <td
                      class="pointer"
                      @click="toDetails(brandDetails)"
                    >{{item.signedBrand.brandName}}</td>
                  </tr>
                  <tr>
                    <td>签约楼层</td>
                    <td>{{item.signedBrand.floor}}</td>
                    <td>签约铺位</td>
                    <td>{{item.signedBrand.berthNo}}</td>
                  </tr>
                  <tr>
                    <td>计租方式</td>
                    <td v-if="item.signedBrand.rentWay === 1">固租</td>
                    <td v-if="item.signedBrand.rentWay === 2">扣点</td>
                    <td v-if="item.signedBrand.rentWay === 3">固租+扣点（两者取其高）</td>
                    <td>租金标准</td>
                    <td>{{item.signedBrand.rentPrice ? item.signedBrand.rentPrice + '元/月/㎡': '-'}}</td>
                  </tr>
                  <tr>
                    <td>扣点比例</td>
                    <td colspan="3">{{item.signedBrand.rentPercent ? item.signedBrand.rentPercent + '%': '-'}}</td>
                  </tr>
                  <tr>
                    <td>租赁时间</td>
                    <td>{{item.signedBrand.rentBeg ? item.signedBrand.rentBeg + '至' + item.signedBrand.rentEnd: '-'}}</td>
                    <td>免租时间</td>
                    <td>{{item.signedBrand.freeBeg ? item.signedBrand.freeBeg + '至' + item.signedBrand.freeEnd: '-'}}</td>
                  </tr>
                  <tr>
                    <td>租赁协议</td>
                    <td colspan="3">{{item.signedBrand.rentRemark ? item.signedBrand.rentRemark : '-'}}</td>
                  </tr>
                </table>
              </div>
              <p class="record-txt">{{item.followRemark}}</p>
              <div class="w-from">
                <ul class="w-time-info">
                  <li>
                    跟进时间：{{item.followTime || '-'}}
                  </li>
                  <li>
                    提交时间：{{item.createTime || '-'}}
                  </li>
                </ul>
                <span
                  class="w-add-follow"
                  v-if="(item.approvalStatus === '审批中' && item.operationState === '新增') ||
                (item.operationState === '修改跟进人' && item.approvalStatus === '审批中') ||
                (item.operationState === '修改计划时间' && item.approvalStatus === '审批中') ||
                (item.operationState === '修改完成时间' && item.approvalStatus === '审批中') ||
                (item.operationState === '删除' && item.approvalStatus === '审批中')"
                  @click="releaseApproval(item)"
                >审批进度</span>
                <span
                  class="w-add-follow"
                  @click="addFollow(index)"
                  v-else
                >添加跟进</span>
              </div>
              <div
                class="w-add-follow-input"
                v-for="(msg, key) in item.subMsgs"
                :key="key"
              >
                <i
                  class="el-icon-close"
                  v-if="!msg.followRemark"
                  @click="hideInput()"
                ></i>
                <div class="w-name">
                  <span>{{msg.createUserName}}： <i>{{msg.createTime}}</i></span>
                  <span
                    v-if="user.userId === msg.createUserId"
                    class="el-icon-delete"
                    @click="deleteMsg(msg)"
                  ></span>
                  <p class="txt">{{msg.followRemark}}</p>
                </div>
              </div>
              <div
                class="w-text-input"
                v-show="inpuntIndex === index"
              >
                <i
                  class="el-icon-close"
                  @click="hideInput()"
                ></i>
                <p class="name-time">
                  {{user.userName}}
                </p>
                <input
                  type="text"
                  v-model="inputVal"
                  placeholder="请输入跟进记录"
                >
                <span @click="addSubMessage(item)">确定</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        class="follow-wrap"
        v-show="editFollow"
      >
        <div class="edit-follow-wrap">
          <i
            @click="hideInvestment"
            class="el-icon-close"
          ></i>
          <h3>修改跟进人</h3>
          <div class="brand-details">
            <div
              class="brand-head"
              style="border-bottom:1px solid rgba(234,210,183,.5);"
            >
              <span>品牌名称</span>
              <span>当前招商状态</span>
              <span>跟进人</span>
              <span>上次跟进时间</span>
            </div>
            <div class="brand-body">
              <span :title="brandDetails.brandName">{{brandDetails.brandName}}</span>
              <span>{{brandDetails.taskState}}</span>
              <span>{{brandDetails.followUserName ? brandDetails.followUserName: '-'}}</span>
              <span>{{brandDetails.lastFollowTime ? brandDetails.lastFollowTime: '-'}}</span>
            </div>
          </div>
          <div class="remarksmk">
            任务跟进人：
            <el-select
              v-model="followName"
              @change="changeFollow"
              placeholder="请选择任务跟进人"
            >
              <el-option
                v-for="item in followList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="btn-wrap">
            <span
              @click="hideInvestment"
              class="cancal"
            >取消</span>
            <span @click="determineClick">确定</span>
          </div>
        </div>
      </div> -->
      <!-- 修改跟进人 -->
      <el-dialog
        title="修改跟进人"
        :visible.sync="editFollow"
        width="400px"
        top="350px"
        :show-close="false"
        custom-class="w-block-dialog"
        :before-close="hideInvestment"
      >
        <div class="brand-investment-wrap">
          <div>
            <div class="input-wrap">
              <div class="title">跟进人： </div>
              <div
                style="width: 100%;"
                class="cascader-style height36-input"
              >
                <el-select
                  popper-class="w-block-select-down yellow-select-panel"
                  v-model="followName"
                  @change="changeFollow"
                  placeholder="请选择"
                  width="280px;"
                  size='small'
                >
                  <el-option
                    v-for="item in followList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="btns-wrap">
            <div class="btns">
              <span
                class="sure"
                @click="determineClick"
              >确定</span>
              <span
                class="cancal"
                @click="hideInvestment"
              >取消</span>
            </div>
          </div>
        </div>
      </el-dialog>
      <div v-if="maskShow">
        <add-mask
          v-if="invesmtentItem !== {}"
          :invesmtentItem="invesmtentItem"
          :projectId="projectId"
          :projectName="projectName"
          :maskEdit="maskEdit"
          @hideSingleMask="hideSingleMask"
        ></add-mask>
      </div>
      <el-drawer
        class="black-drawer"
        size="856px"
        :with-header="false"
        :visible.sync="investmentShow"
        direction="rtl"
      >
        <div v-if="investmentShow">
          <edit-states
            :invesmtentItem="invesmtentItem"
            @hideInvestmentEmit="hideInvestmentEmit"
          ></edit-states>
        </div>
      </el-drawer>
      <approval-progress-popup
        v-if="isShowApproval"
        :taskId="taskId"
        :projectId="projectId"
        :auditType="auditType"
        @hideApprovalEmit="hideApprovalEmit"
      ></approval-progress-popup>
      <approval-edit-follow-popup
        v-if="isShowEditApproval"
        :taskId="taskId"
        :projectId="projectId"
        @hideApprovalEmit="hideEditApprovalEmit"
      ></approval-edit-follow-popup>
      <approval-delete-popup
        v-if="isShowDeleteApproval"
        :taskId="taskId"
        :projectId="projectId"
        :brandDetails="brandDetails"
        @hideApprovalEmit="hideDeleteApprovalEmit"
      ></approval-delete-popup>
      <div
        class="edit-follow-wrap2"
        id="mycanvas"
      >
        <div class="edit-head">
          变更内容
        </div>
        <table>
          <tbody>
            <tr>
              <td>项目名称</td>
              <td>{{brandDetails.projectName}}</td>
              <td>落位楼宇楼层</td>
              <td
                colspan="2"
                v-if="planList && planList.length > 0"
              >{{planList[0].floor}}</td>
              <td
                colspan="2"
                v-else
              >-</td>
            </tr>
            <tr>
              <td>落位铺位</td>
              <td v-if="planList && planList.length > 0">{{planList[0].berthNo}}</td>
              <td v-else>-</td>
              <td>是否首选落位</td>
              <td
                colspan="2"
                v-if="planList && planList.length > 0"
              >{{planList[0].isFirst === 1 ? '是': '否'}}</td>
              <td
                colspan="2"
                v-else
              >-</td>
            </tr>
            <tr>
              <td>招商品牌</td>
              <td>{{brandDetails.brandName}}</td>
              <td>品牌业态</td>
              <td colspan="2">{{brandDetails.industry}}</td>
            </tr>
            <tr>
              <td>序号</td>
              <td>变更类型</td>
              <td>跟进人(原)</td>
              <td>跟进人(新)</td>
            </tr>
            <tr>
              <td>1</td>
              <td>修改</td>
              <td>{{brandDetails.followUserName ? brandDetails.followUserName : '-'}}</td>
              <td>{{selectFollowList.label ? selectFollowList.label : '-'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="delete-wrap"
        id="mycanvas2"
      >
        <!-- <div class="delete-head">招商任务变更申请单</div> -->
        <!-- <div>
          <table>
            <tbody>
              <tr>
                <td>申请人</td>
                <td>{{brandDetails.createUserName}}</td>
                <td>申请时间</td>
                <td>{{nowTime}}</td>
                <td>项目名称</td>
                <td>{{brandDetails.projectName}}</td>
              </tr>
            </tbody>
          </table>
        </div> -->
        <div class="edit-head">变更内容</div>
        <div>
          <table>
            <thead>
              <tr>
                <td>序号</td>
                <td>变更类型</td>
                <td>招商品牌</td>
                <td>品牌业态</td>
                <td>落位楼宇楼层</td>
                <td>落位铺位</td>
                <td>是否首选落位</td>
                <td>跟进人</td>
                <td>计划开始时间</td>
                <td>计划完成时间</td>
                <td>实际开始时间</td>
                <td>实际完成时间</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{{auditType}}</td>
                <td>{{brandDetails.brandName}}</td>
                <td>{{brandDetails.industry}}</td>
                <td v-if="planList && planList.length > 0">{{planList[0].floor}}</td>
                <td v-else>-</td>
                <td v-if="planList && planList.length > 0">{{planList[0].berthNo}}</td>
                <td v-else>-</td>
                <td v-if="planList && planList.length > 0">{{planList[0].isFirst === 1 ? '是': '否'}}</td>
                <td v-else>-</td>
                <td>{{brandDetails.followUserName || '-'}}</td>
                <td>{{(auditType==='修改计划时间'?this.timeDialogItem0:brandDetails.planStartTime) || '-'}}</td>
                <td>{{(auditType==='修改计划时间'?this.timeDialogItem1:brandDetails.planFinishTime) || '-'}}</td>
                <td>{{(auditType==='修改完成时间'?this.timeDialogItem0:brandDetails.realityStartTime) || '-'}}</td>
                <td>{{(auditType==='修改完成时间'?this.timeDialogItem1:brandDetails.realityFinishTime) || '-'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div
        class="delete-wrap"
        id="mycanvas-date">
        <div class="edit-head">变更内容</div>
        <table>
          <tr v-if="this.timeDialogIndex === 0">
            <td>计划开始时间：</td>
            <td>{{timeDialogItem0}}</td>
            <td>计划完成时间：</td>
            <td>{{timeDialogItem1}}</td>
          </tr>
          <tr v-else>
            <td>实际开始时间：</td>
            <td>{{timeDialogItem0}}</td>
            <td>实际完成时间：</td>
            <td>{{timeDialogItem1 }}</td>
          </tr>
        </table>
      </div> -->
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
    <el-dialog
      :title="timeDialogIndex?'修改完成时间':'修改计划时间'"
      :visible.sync="timeDialogShow"
      :before-close="closeDialog"
      class="time-dialog"
      top="350px"
      custom-class="w-block-dialog"
      width="405px"
      :show-close="false"
    >
      <ul class="formList">
        <li class="height36-input">
          <span>{{timeDialogIndex?'实际开始时间':'计划开始时间'}}：</span>
          <el-date-picker
            popper-class="black-el-date-picker"
            v-model="timeDialogItem0"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </li>
        <li class="height36-input">
          <span>{{timeDialogIndex?'实际完成时间':'计划完成时间'}}：</span>
          <el-date-picker
            popper-class="black-el-date-picker"
            v-model="timeDialogItem1"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </li>
      </ul>
      <div class="btns-wrap">
        <div class="btns">
          <span
            class="sure"
            @click="submitTimeDialog"
          >确定</span>
          <span
            class="cancal"
            @click="closeDialog"
          >取消</span>
        </div>
      </div>
    </el-dialog>
    <el-drawer
      class="black-drawer"
      size="856px"
      :with-header="false"
      :visible.sync="isBerthManage"
      direction="rtl"
    >
      <div v-if="isBerthManage">
        <intentionBerth
          :projectId="projectId"
          :brandDetails="brandDetails"
          :intentionBerthList="intentionBerthList"
          @hideIntentionEmit="hideIntentionEmit"
        ></intentionBerth>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import api from '@/api'
import { mapGetters } from 'vuex'
import editStates from '@/views/workBench/components/investmentEditStates.vue'
import addMask from '@/views/workBench/components/addSingleMask.vue'
import approvalProgressPopup from '@/views/workBench/components/approvalProgressPopup.vue'
import approvalEditFollowPopup from '@/views/workBench/components/approvalEditFollowPopup.vue'
import approvalDeletePopup from '@/views/workBench/components/approvalDeletePopup.vue'
import intentionBerth from '@/views/workBench/components/intentionBerth.vue'
import { getCookie, powerAuth } from '@/utils'
import html2canvas from 'html2canvas'
import { b64toBlob } from '@/utils/base64.js'
export default {
  data () {
    return {
      loading: true,
      brandDetails: {},
      invesmtentItem: {},
      isDisabled: false, // 是否禁用操作
      auditType: '删除', // 是否禁用操作
      projectId: '',
      planList: [],
      recordList: [],
      inpuntIndex: null,
      inputVal: '',
      editFollow: false,
      followName: '',
      followList: [],
      statesShow: false,
      typeStr: '',
      maskEdit: 0,
      maskShow: false,
      investmentShow: false,
      userAuthorities: [],
      hasPermission: true, // 权限
      hasPermissionAdd: true, // 添加：指“添加招商任务”的操作；
      hasPermissionDel: true, // 删除：指“删除招商计划”的操作；
      hasPermissionFollow: true, // 跟进：指“跟进”状态修改的操作；
      hasPermissionEditfollow: true, // 修改跟进人：指“修改跟进人”的操作；
      hasPermissionLeadingout: true, // 导出：指“导出”的操作；
      hasPermissionEditplantime: true, // 修改计划时间
      hasPermissionEditfinishtime: true, // editfinishtime 修改完成时间
      selectFollowList: {},
      modulesList: [],
      isShowApproval: false,
      isShowEditApproval: false,
      isShowDeleteApproval: false,
      taskId: 0,
      timeDialogShow: false,
      timeDialogIndex: 0,
      timeDialogItem0: '',
      timeDialogItem1: '',
      nowTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      intentionBerthList: [],
      isBerthManage: false
    }
  },
  components: {
    editStates,
    addMask,
    approvalProgressPopup,
    approvalEditFollowPopup,
    approvalDeletePopup,
    intentionBerth
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 217
    })
    if (this.userAuthorities.length) {
      this.taskId = this.$route.query.id
      this.getDetailsFunc()
      this.getFollowListFunc()
      this.getModularListFunc()
      this.hasPermission = Boolean(this.userAuthorities[0].func)
      this.hasPermissionAdd = this.userAuthorities[0].func.indexOf('add') !== -1
      this.hasPermissionDel = this.userAuthorities[0].func.indexOf('del') !== -1
      this.hasPermissionFollow = this.userAuthorities[0].func.indexOf('follow') !== -1
      this.hasPermissionEditfollow = this.userAuthorities[0].func.indexOf('editfollow') !== -1
      this.hasPermissionLeadingout = this.userAuthorities[0].func.indexOf('leadingout') !== -1
      this.hasPermissionEditplantime = powerAuth(217, 'brandtasktime')
      this.hasPermissionEditfinishtime = powerAuth(217, 'editfinishtime')
    } else {
      this.hasPermission = false
      this.loading = true
    }
    // window.addEventListener('popstate', (e) => {
    //   // alert('我监听到了浏览器的返回按钮事件啦')
    //   this.pushHistory()
    // }, false)
  },
  methods: {
    toInvestmentFollowDetail (id) {
      this.$router.push({
        path: '/berthInvestmentManageDetail',
        query: {
          id: id
        }
      })
    },
    handleTimeDialog (index) {
      if (this.isDisabled) {
        return
      }
      if (index === 0) {
        if (!powerAuth(217, 'brandtasktime')) {
          this.hasnotPermission()
          return false
        }
      } else {
        if (!powerAuth(217, 'editfinishtime')) {
          this.hasnotPermission()
          return false
        }
      }
      document.body.style.overflowY = 'hidden'
      this.timeDialogShow = true
      this.timeDialogIndex = index
      this.timeDialogItem0 = ''
      this.timeDialogItem1 = ''
      if (index) { // 完成时间1
        if (this.brandDetails.realityStartTime) {
          this.timeDialogItem0 = this.brandDetails.realityStartTime
        }
        if (this.brandDetails.realityFinishTime) {
          this.timeDialogItem1 = this.brandDetails.realityFinishTime
        }
      } else { // 计划时间0
        if (this.brandDetails.planStartTime) {
          this.timeDialogItem0 = this.brandDetails.planStartTime
        }
        if (this.brandDetails.planFinishTime) {
          this.timeDialogItem1 = this.brandDetails.planFinishTime
        }
      }
    },
    closeDialog () {
      this.timeDialogShow = false
      document.body.style.overflowY = 'auto'
    },
    submitTimeDialog () {
      const list = ['计划', '完成']
      if (!this.timeDialogItem0) {
        this.$message({
          type: 'warning',
          message: `请选择${list[this.timeDialogIndex]}开始时间`
        })
        return
      } else if (!this.timeDialogItem1) {
        this.$message({
          type: 'warning',
          message: `请选择${list[this.timeDialogIndex]}结束时间`
        })
        return
      } else if (dayjs(this.timeDialogItem1).isBefore(dayjs(this.timeDialogItem0))) {
        this.$message({
          type: 'warning',
          message: '结束时间应在开始时间之后'
        })
        return
      }
      const paramsDate = {
        planFinishTime: '',
        planStartTime: '',
        realityFinishTime: '',
        realityStartTime: '',
        taskId: this.brandDetails.id,
        type: this.timeDialogIndex
      }
      const data = {
        projectId: this.projectId,
        taskId: this.$route.query.id,
        typeId: this.timeDialogIndex
      }
      if (this.timeDialogIndex === 0) {
        this.auditType = '修改计划时间'
        data.planStartTime = this.timeDialogItem0
        data.planFinishTime = this.timeDialogItem1
        paramsDate.planStartTime = this.timeDialogItem0
        paramsDate.planFinishTime = this.timeDialogItem1
      } else {
        this.auditType = '修改完成时间'
        data.realityStartTime = this.timeDialogItem0
        data.realityFinishTime = this.timeDialogItem1
        paramsDate.realityStartTime = this.timeDialogItem0
        paramsDate.realityFinishTime = this.timeDialogItem1
      }
      data.taskId = this.$route.query.id
      this.isApproval().then((res) => { // 判断ys是否开启审批，ys、高德都开启了审批才发送审批，否则都直接提交
        if (res.data.data === 1) {
          const params = {
            projectId: this.projectId,
            type: '删除' // 高德那边的流程是这个传删除，逻辑上有问题
          }
          this.axios.post(api.isGdwsApprovalChainIsNotRnabled, params) // 判断高德是否开启审批
            .then((res) => {
              if (res.data.data === 1) { // 如果高德开启了审批
                this.saveImg('mycanvas2').then((res) => {
                  data.url = res
                  this.axios.post(api.updateTaskPlanOrRealityTimeChain, data)
                    .then((res) => {
                      if (res.data.code === 0) {
                        this.$message({
                          type: 'success',
                          message: '提交成功，' + list[this.timeDialogIndex] + '时间将在审批通过后变更！'
                        })
                        this.getDetailsFunc()
                        this.getFollowListFunc()
                        this.timeDialogShow = false
                      }
                      document.body.style.overflowY = 'auto'
                    })
                })
              } else { // 如果高德没有开启审批
                this.axios.post(api.updateTaskPlanOrRealityTime, paramsDate).then(res => {
                  if (res.data.code === 0) {
                    this.$message({
                      type: 'success',
                      message: res.data.msg
                    })
                    this.getDetailsFunc()
                    this.getFollowListFunc()
                    this.timeDialogShow = false
                    document.body.style.overflowY = 'auto'
                  }
                }, rej => {
                  console.log(rej)
                }).catch(err => {
                  console.log(err)
                })
              }
            })
        } else { // 如果ys没有开启审批
          this.axios.post(api.updateTaskPlanOrRealityTime, paramsDate).then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.getDetailsFunc()
              this.getFollowListFunc()
              this.timeDialogShow = false
              document.body.style.overflowY = 'auto'
            }
          }, rej => {
            console.log(rej)
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 12
          })
        })
    },
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    },
    getDetailsFunc () {
      this.axios.post(api.getInvestmentDetails, { taskId: this.$route.query.id })
        .then((res) => {
          this.brandDetails = res.data.data
          // 0:审批中 所有不可以点击
          // 1:驳回 所有高亮
          // 2:完成 所有高亮
          // 3:新增审批中 所有不可以点击
          // 4:新增驳回 删除高亮其他不可以点击
          if (this.brandDetails.taskState === '待跟进') {
            this.brandDetails.color = '#FFA134'
          } else if (this.brandDetails.taskState === '已联系') {
            this.brandDetails.color = '#3494FF'
          } else if (this.brandDetails.taskState === '已看场') {
            this.brandDetails.color = '#26CBE9'
          } else if (this.brandDetails.taskState === '已报价') {
            this.brandDetails.color = '#FEF054'
          } else if (this.brandDetails.taskState === '已预定') {
            this.brandDetails.color = '#FE7429'
          } else if (this.brandDetails.taskState === '已签约') {
            this.brandDetails.color = '#16DA64'
          } else if (this.brandDetails.taskState === '已搁置') {
            this.brandDetails.color = '#C750FF'
          } else {
            this.brandDetails.color = '#999999'
          }
          // if ([1, 2].indexOf(this.brandDetails.isApprove) !== -1) {
          //   this.isDisabled = false
          // } else {
          //   this.isDisabled = true
          // }
          this.invesmtentItem = this.brandDetails
          this.projectName = this.brandDetails.projectName
          this.projectId = this.brandDetails.projectId
          this.getFollow()
          this.getIntentionBerthList()
          this.loading = false
        })
      this.axios.post(api.listPlanBrand, { taskId: this.$route.query.id })
        .then((res) => {
          this.planList = res.data.data
          this.loading = false
        })
    },
    getIntentionBerthList () {
      this.axios.post(api.intentionToPlaceTheBunk, { gbid: this.brandDetails.gbid, projectId: this.projectId })
        .then((res) => {
          this.intentionBerthList = res.data.data
          this.loading = false
        })
    },
    getFollowListFunc () {
      this.axios.post(api.followRecordList, { taskId: this.$route.query.id })
        .then((res) => {
          this.recordList = res.data.data
        })
    },
    deleteInvestmentClick () {
      if (!powerAuth(217, 'delbrandtask')) {
        this.hasnotPermission()
        return false
      }
      if (this.brandDetails.isDelete === 1) {
        this.$confirm('请确认是否要删除该招商任务?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'invitePosition'
        }).then((res) => {
          this.axios.post(api.deleteInvestment, { taskId: this.$route.query.id })
            .then((res) => {
              if (res.data.code === 0) {
                // 删除成功 跳回招商任务列表
                this.$router.push({
                  path: '/investmentFollowManage'
                })
              }
            })
        })
      } else {
        // if (this.brandDetails.isApprove !== 0 && this.brandDetails.isApprove !== 3) {
        if (!powerAuth(217, 'delbrandtask')) {
          this.hasnotPermission()
          return false
        }
        this.$confirm('请确认是否要删除该招商任务?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'invitePosition'
        }).then((res) => {
          this.isApproval().then((res) => {
            if (res.data.data === 1) {
              const params = {
                projectId: this.projectId,
                taskId: this.$route.query.id,
                type: '删除'
              }
              this.axios.post(api.isGdwsApprovalChainIsNotRnabled, params)
                .then((res) => {
                  if (res.data.data === 1) {
                    this.auditType = '删除'
                    this.saveImg('mycanvas2').then((res) => {
                      this.axios.post(api.deleteChinaMerchantsTask, { taskId: this.$route.query.id, url: res })
                        .then((res) => {
                          if (res.data.code === 0) {
                            this.$message({
                              type: 'success',
                              message: '提交成功，将在审批通过后删除！'
                            })
                            this.getDetailsFunc()
                            this.getFollowListFunc()
                          }
                        })
                    })
                  } else {
                    this.axios.post(api.deleteInvestment, { taskId: this.$route.query.id })
                      .then((res) => {
                        if (res.data.code === 0) {
                          // 删除成功 跳回招商任务列表
                          this.$router.push({
                            path: '/investmentFollowManage'
                          })
                        }
                      })
                  }
                })
            } else {
              this.axios.post(api.deleteInvestment, { taskId: this.$route.query.id })
                .then((res) => {
                  if (res.data.code === 0) {
                    // 删除成功 跳回招商任务列表
                    this.$router.push({
                      path: '/investmentFollowManage'
                    })
                  }
                })
            }
          })
        })
      }
      // }
    },
    addFollow (index) {
      this.inputVal = ''
      this.inpuntIndex = index
    },
    addSubMessage (item) {
      if (this.user.topuid !== 6467) {
        if (this.inputVal.length > 100) {
          this.$message({
            type: 'warning',
            message: '跟进消息不能超出100字，请重新输入！'
          })
          return
        }
      }
      this.axios.post(api.saveFollowMsg, { taskId: item.taskId, pid: item.id, followRemark: this.inputVal })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '添加跟进人信息成功！'
            })
            this.inpuntIndex = null
            this.inputVal = ''
            this.getDetailsFunc(this.id)
            this.getFollowListFunc()
          }
        })
    },
    deleteMsg (msg) {
      this.$confirm('确定删除跟进人信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'invitePosition'
      }).then((res) => {
        this.axios.post(api.deleteFollowSubMsg, {
          followId: msg.id,
          taskId: msg.taskId
        })
          .then((res) => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '删除跟进人信息成功！'
              })
              this.getFollowListFunc()
            }
          })
      })
    },
    hideSingleMask (type) {
      if (type) {
        this.getDetailsFunc(this.id)
      }
      this.maskShow = false
      document.body.style.overflowY = 'auto'
    },
    editFollowFunc () {
      if (!powerAuth(217, 'setfollow')) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      if (this.brandDetails.taskState === '已签约' || (this.isDisabled)) {
      } else {
        this.editFollow = true
      }
    },
    changeFollow (val) {
      this.followId = val
      this.selectFollowList = this.followList.find((item) => {
        return item.value === val
      })
    },
    getFollow () {
      this.axios.post(api.getFollowList, { isShow: 1, projectId: this.projectId })
        .then((res) => {
          this.followList = res.data.data.map((item) => {
            const obj = {
              value: item.userId,
              label: item.userName
            }
            return obj
          })
        })
    },
    editFollowStatus (item) {
      if (!powerAuth(217, 'follow')) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      if (this.brandDetails.taskState === '已签约') {
        return false
      }
      this.invesmtentItem = item
      this.invesmtentItem.brand = item
      this.invesmtentItem.task = item
      this.invesmtentItem.taskId = item.task.id
      this.maskEdit = 0
      if (item.berths && item.berths.length > 0) {
        this.invesmtentItem.floor = item.berths[0].floor
        this.invesmtentItem.berthNo = item.berths[0].berthNo
        this.invesmtentItem.berthId = item.berths[0].berthId
      }
      this.investmentShow = true
    },
    mousetoutState () {
      this.statesShow = false
    },
    followItem (item, type) {
      this.invesmtentItem = item
      if (this.brandDetails.taskState === '已签约') {
      } else {
        if (this.planList && this.planList.length > 0) {
          this.invesmtentItem.floor = this.planList[0].floor
          this.invesmtentItem.berthNo = this.planList[0].berthNo
          this.invesmtentItem.berthId = this.planList[0].berthId
        }
        this.typeStr = type
        if (type === '已签约') {
          if (this.modulesList[0].flag) {
            if (this.modulesList[0].userFlag) {
              window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/add/mainBodyInfo?menuCode=12&tokenval=' + getCookie('wisdom_token'), '_blank')
              this.statesIdx = null
            } else {
              this.$message({
                type: 'warning',
                message: '抱歉，此账号暂无权限！'
              })
            }
          } else {
            // 添加签约品牌
            // this.maskEdit = 0
            // this.invesmtentItem.brand = item
            // this.invesmtentItem.task = item
            // this.maskShow = true
            // document.body.style.overflowY = 'hidden'
            this.$message({
              type: 'warning',
              message: '抱歉，集团暂未开通此应用模块！'
            })
          }
        } else if (type === '已预定') {
          if (this.modulesList[0].flag) {
            if (this.modulesList[0].userFlag) {
              window.open(this.$gaodeDomain + '/omsviews/rent/contract/intentionContract/add/mainBodyInfo?menuCode=12&tokenval=' + getCookie('wisdom_token'), '_blank')
              this.statesIdx = null
            } else {
              this.$message({
                type: 'warning',
                message: '抱歉，此账号暂无权限！'
              })
            }
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，集团暂未开通此应用模块！'
            })
          }
        } else {
          // 修改为其他
          this.invesmtentItem.brand = item
          this.invesmtentItem.task = item
          this.investmentShow = true
          document.body.style.overflowY = 'hidden'
        }
        this.statesShow = false
      }
    },
    toContractDetail (item) {
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/details/mainBodyInfo?contractCode=' + item.contractCode + '&contractId=' + item.contractId + '&mallCode=' + this.projectId + '&menuCode=12&tokenval=' + getCookie('wisdom_token') + '&operateStep=3', '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
    },
    showStates () {
      if (this.brandDetails.taskState === '已签约' || (this.isDisabled)) {
      } else {
        this.statesShow = !this.statesShow
      }
    },
    showConfim () {
      this.axios.post(api.countPpyAddLink, { gbid: this.brandDetails.gbid })
        .then((res) => {
          if (res.data.data === 0) {
            this.$confirm('是否需要同步添加品牌联系人数据？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              customClass: 'invitePosition'
            }).then((res) => {
              this.addContact()
            })
          }
        })
    },
    hideInvestmentEmit (type) {
      if (type) {
        this.getDetailsFunc(this.id)
        this.getFollowListFunc()
      }
      this.statesShow = false
      this.investmentShow = false
    },
    determineClick () {
      const data = {}
      data.followUserId = this.selectFollowList.value
      data.followUserName = this.selectFollowList.label
      data.taskId = this.$route.query.id
      this.isApproval().then((res) => {
        if (res.data.data === 1) {
          const params = {
            projectId: this.projectId,
            type: '修改'
          }
          this.axios.post(api.isGdwsApprovalChainIsNotRnabled, params)
            .then((res) => {
              if (res.data.data === 1) {
                this.saveImg('mycanvas').then((res) => {
                  data.url = res
                  this.axios.post(api.updateUserChina, data)
                    .then((res) => {
                      if (res.data.code === 0) {
                        this.$message({
                          type: 'success',
                          message: '提交成功，跟进人将在审批通过后变更！'
                        })
                        this.getDetailsFunc()
                        this.getFollowListFunc()
                        this.editFollow = false
                      }
                    })
                })
              } else {
                this.axios.post(api.editFollowName, data)
                  .then((res) => {
                    if (res.data.code === 0) {
                      this.$message({
                        type: 'success',
                        message: '修改跟进人成功！'
                      })
                      this.getDetailsFunc()
                      this.getFollowListFunc()
                      this.editFollow = false
                    }
                  })
              }
            })
        } else {
          this.axios.post(api.editFollowName, data)
            .then((res) => {
              if (res.data.code === 0) {
                this.$message({
                  type: 'success',
                  message: '修改跟进人成功！'
                })
                this.getDetailsFunc()
                this.getFollowListFunc()
                this.editFollow = false
              }
            })
        }
      })
    },
    // 是否启用审批
    isApproval () {
      return new Promise((resolve) => {
        this.axios.post(api.approvalChainIsNotRnabled).then((res) => {
          if (res.status === 200) {
            resolve(res)
          }
        })
      })
    },
    saveImg (val) {
      return new Promise((resolve) => {
        window.pageYoffset = 0
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        // 先获取你要转换为img的dom节点
        var node = document.getElementById(val)// 传入的id名称
        // console.log("node", node);
        var width = node.offsetWidth // dom宽
        var height = node.offsetHeight // dom高
        var scale = 1.5 // 放大倍数 这个相当于清晰度 调大一点更清晰一点
        html2canvas(node, {
          width: width,
          heigth: height,
          backgroundColor: '#ffffff', // 背景颜色 为null是透明
          dpi: window.devicePixelRatio * 2, // 按屏幕像素比增加像素
          scale: scale,
          X: 0,
          Y: 0,
          scrollX: -3, // 如果左边多个白边 设置该偏移-3 或者更多
          scrollY: 0,
          useCORS: true, // 是否使用CORS从服务器加载图像 !!!
          allowTaint: true // 是否允许跨域图像污染画布  !!!
        }).then(canvas => {
          var url = canvas.toDataURL() // 这里上面不设值cors会报错
          const base64 = b64toBlob(url)
          const files = new window.File([base64], 'approval.png', { type: 'image/png' })
          var forms = new FormData()
          var configs = {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
          forms.append('file', files)
          this.axios.post(api.ChainPicture, forms, configs)
            .then((res) => {
              if (res.status === 200) {
                resolve(res.data.data)
              }
            })
        })
      })
    },
    hideInvestment () {
      this.editFollow = false
      document.body.style.overflowY = 'auto'
    },
    hideInput () {
      this.inpuntIndex = null
    },
    addContact () {
      if (!this.isDisabled) {
        if (!powerAuth(217, 'addlinkman')) {
          this.$message.error({
            message: '暂无权限，如需开通权限，请联系管理员开通'
          })
          return false
        }
        let temUrl = ''
        if (process.env.NODE_ENV === 'development') {
          temUrl = 'http://' + document.domain + ':8030'
        }
        window.open(temUrl + '/brand/#/issue/addContact?brandId=' + this.brandDetails.brandId + '&gbid=' + this.brandDetails.gbid + '&brandName=' + encodeURIComponent(encodeURIComponent(this.brandDetails.brandName)) + '&taskId=' + this.brandDetails.id + '&from=investment', '_blank')
      }
    },
    toContactList (item) {
      const brandDetailsHref = this.$router.resolve({
        path: '/brandDetail',
        query: {
          id: item.brandId,
          source: 1,
          gbid: item.gbid,
          questionState: 0,
          from: 'contactWay'
        }
      })
      window.open('/' + brandDetailsHref.href, '_blank')
    },
    // 发布审批
    releaseApproval (item) {
      if ((item.followState === '未发布' && item.operationState === '新增') ||
        item.operationState === '修改计划时间' ||
        item.operationState === '修改完成时间') {
        this.auditType = item.operationState
        this.isShowApproval = true
      } else if (
        (item.operationState === '修改跟进人'
        ) && item.approvalStatus === '审批中'
      ) {
        this.isShowEditApproval = true
      } else if (item.operationState === '删除' && item.approvalStatus === '审批中') {
        this.isShowDeleteApproval = true
      }
      document.body.style.overflowY = 'hidden'
    },
    hideApprovalEmit () {
      document.body.style.overflowY = 'auto'
      this.isShowApproval = false
    },
    hideEditApprovalEmit () {
      document.body.style.overflowY = 'auto'
      this.isShowEditApproval = false
    },
    hideDeleteApprovalEmit () {
      document.body.style.overflowY = 'auto'
      this.isShowDeleteApproval = false
    },
    toDetails (item) {
      const userAuthorities = this.user.confIds.filter(item => {
        return item.id === 164
      })
      if (userAuthorities.length > 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token + '&brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName, '_blank')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，您暂无查看企业品牌详情权限！'
        })
      }
    },
    berthManage () {
      if (!powerAuth(217, 'editintentionberth')) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      this.isBerthManage = true
    },
    deleteIntention (item) {
      if (!powerAuth(217, 'editintentionberth')) {
        this.$message.error({
          message: '暂无权限，如需开通权限，请联系管理员开通'
        })
        return false
      }
      this.axios.post(api.deleteIntentionToPlaceTheBunk, {
        berthId: item.berthId,
        gbid: this.brandDetails.gbid,
        isDelete: 0,
        projectId: this.projectId
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getDetailsFunc()
            this.getFollowListFunc()
            // if (res.data.data === 999) {
            //   this.$confirm('本铺位为当前品牌的最后一个意向落位铺位，删除后本招商任务将关闭，是否确认删除？', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning',
            //     customClass: 'invitePosition'
            //   }).then((res) => {
            //     this.axios.post(api.deleteIntentionToPlaceTheBunk, {
            //       berthId: item.berthId,
            //       gbid: this.brandDetails.gbid,
            //       isDelete: 1,
            //       projectId: this.projectId
            //     })
            //       .then((res) => {
            //         if (res.data.code === 0) {
            //           // 删除成功 跳回招商任务列表
            //           this.$router.push({
            //             path: '/investmentFollowManage'
            //           })
            //         }
            //       })
            //   }).catch((err) => {
            //     console.log(err)
            //   })
            // } else {
            //   this.$message({
            //     type: 'success',
            //     message: '删除成功!'
            //   })
            //   this.getDetailsFunc()
            //   this.getFollowListFunc()
            // }
          }
        })
    },
    hideIntentionEmit (type) {
      if (type) {
        this.getDetailsFunc()
        this.getFollowListFunc()
      }
      this.isBerthManage = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-follow-detail-wrap
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  .w-detail-head
    display flex
    background-color #272930
    padding 0 32px
    height 192px
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    align-items center
    .w-detail-info
      height 120px
      .w-info-name
        font-size 26px
        color #ffffff
        height 36px
        display flex
        align-items center
        .follow-status
          font-size 14px
          .dots
            display inline-block
            width 10px
            height 10px
            border-radius 50%
            margin-left 16px
            margin-right 5px
            vertical-align middle
    .w-info-msg
      margin-top 10px
      font-size 14px
      margin-bottom 16px
      .w-title
        color #999999
      .w-text
        margin-right 36px
        color #ffffff
      .cursor
        text-decoration underline
    .w-info-btns
      position relative
      span
        display inline-block
        width 104px
        height 26px
        line-height 26px
        text-align center
        font-size 13px
        border 1px solid #ffa134
        color #ffa134
        border-radius 3px
        margin-right 10px
        cursor pointer
        &:hover
          background-color #ffa134
          color #ffffff
        &.gray
          background rgba(226, 226, 226, 0.8)
          border-color rgba(226, 226, 226, 0.8)
          color #fff
  .w-task-detail-wrap
    // background-color #272930
    padding 0 0 24px 0
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    .follow-detail-bg
      background-color #272930
      padding-bottom 40px
      margin-top 16px
    .w-task-table
      margin-top 36px
      padding 0 32px
      background-color #272930
      table
        width 100%
        border 1px solid #373A43
        border-collapse collapse
        margin-bottom 20px
        tr
          vertical-align middle
          border-bottom 1px solid #373A43
          td
            text-align center
            padding 13px
            font-size 14px
            color #fff
          td:nth-child(2n+1)
            background #2C2E38
            color #B2B3BD
            font-size 14px
  .w-task-content
    position relative
    padding-left 16px
    margin-top 32px
    margin-left 32px
    .w-liner
      position absolute
      top 0
      left 7px
      width 1px
      height 100%
      background-color rgba(255, 161, 52, 0.2)
    .w-record-item
      position relative
      width calc(100% - 38px)
      padding 20px 32px 45px
      margin-bottom 40px
      margin-left 4px
      background-color rgba(30, 30, 35, 0.5)
      .w-point
        position absolute
        top 0
        left -21px
        display block
        width 16px
        height 16px
        border-radius 8px
        background-color #ffa134
      .w-item-title
        font-size 16px
        display flex
        justify-content space-between
        color #ffffff
        i
          color #ffa134
          cursor pointer
          margin-left 20px
          font-size 12px
        .w-time
          color #999999
      .w-task-item-table
        margin-top 20px
        table
          width 100%
          border 1px solid #5d5e64
          border-collapse collapse
          tr
            vertical-align middle
            border-bottom 1px solid #5d5e64
            td
              text-align center
              padding 13px
              font-size 14px
              color #fff
            td:nth-child(2n+1)
              width 150px
              background-color #3e4046
      .w-from
        color #ffffff
        width 100%
        font-size 16px
        margin-top 28px
        display flex
        -webkit-box-pack justify
        justify-content space-between
        span i
          color #999999
          margin-left 10px
        .w-add-follow
          color #ffa134
          cursor pointer
        .w-time-info
          position relative
          color #808191
          font-size 14px
          li:first-child
            margin-bottom 5px
      .w-add-follow-input
        position relative
        border 1px solid rgba(226, 226, 226, 0.1)
        padding 20px
        margin-top 20px
        i
          position absolute
          right 15px
          top 15px
          cursor pointer
          color #fff
        .w-name
          margin-bottom 20px
          font-size 14px
          color #fff
          i
            margin-right 20px
          .txt
            font-size 16px
            margin-top 16px
    .w-text-input
      margin-top 30px
      position relative
      border 1px solid #FFA134
      margin-top 20px
      padding 20px
      input
        width calc(100% - 104px)
        height 40px
        background none
        border 1px solid rgba(226, 226, 226, 0.1)
        padding-left 15px
        color #fff
        font-size 14px
        margin-top 20px
      span
        display inline-block
        width 80px
        height 42px
        background #ffa134
        line-height 42px
        color #fff
        text-align center
        cursor pointer
      .el-icon-close
        position absolute
        right 15px
        top 15px
        cursor pointer
        color #fff
      .name-time
        color #fff
        font-size 14px
.states-wrap
  position absolute
  left 30px
  top 35px
  width 75px
  background rgba(39, 41, 48, 1)
  box-shadow 0px 7px 35px 0px rgba(0, 0, 0, 0.43)
  z-index 111
  text-align center
  color #ffffff
  p
    height 32px
    line-height 32px
    font-size 14px
    cursor pointer
    &:hover
      color #FFA134
.more-contact
  margin-right 10px
  margin-top 20px
  padding-bottom 10px
  color #fff
  font-size 14px
.record-txt
  margin-top 16px
  font-size 16px
  color #fff
.edit-follow-wrap2
  position fixed
  left 5px
  top 50px
  width 1400px
  padding 0px
  background #fff
  text-align center
  z-index -1
  box-sizing border-box
  .edit-head
    padding-bottom 20px
    padding-top 20px
    font-size 24px
    color #000
    font-weight 500
  table
    width 100%
    margin auto
    border-top 1px solid #000
    border-left 1px solid #000
    td
      height 47px
      border-right 1px solid #000
      border-bottom 1px solid #000
      font-size 14px
      text-align center
      color #000
.delete-wrap
  position fixed
  left 0
  top 50px
  width 1400px
  padding 0px
  background #fff
  text-align center
  z-index -111
  box-sizing border-box
  .edit-head
    padding-top 20px
    padding-bottom 20px
    font-size 24px
    color #000
    font-weight 500
  table
    width 100%
    margin auto
    margin-bottom 20px
    margin-top -1px
    border-top 1px solid #000
    border-left 1px solid #000
    td
      height 47px
      border-right 1px solid #000
      border-bottom 1px solid #000
      font-size 14px
      text-align center
      color #000
.time-dialog
  ul
    li
      margin 10px 0 10px 0px
      span
        color #fff
.status
  display inline-block
  width 10px
  height 10px
  border-radius 5px
  background #FFA134
  margin-right 5px
.circle-green
  background #70b603 !important
.circle-red
  background #d9001b !important
.intended-berth-list
  background #272930
  margin-top 16px
  .w-task-title
    display flex
    .intended-berth-mgn
      margin-left auto
      font-size 16px
      color #fff
      margin-right 24px
      margin-top 13px
      cursor pointer
      .icon-intended-berth
        margin-right 4px
  .intended-table-wrap
    padding 16px 40px 32px 40px
    table
      width 100%
      border-collapse collapse
      thead
        tr
          height 54px
          th
            text-align left
            padding-left 16px
            font-size 14px
            color #B2B3BD
            border-bottom 1px solid #373A43
      tbody
        tr
          height 52px
          td
            text-align left
            padding-left 16px
            font-size 14px
            color #808191
            border-bottom 1px solid #373A43
            &.is-first
              position relative
              color #fff
              text-decoration underline
              cursor pointer
              &:hover
                color #f89407
              span
                position absolute
                display inline-block
                border 1px solid #fff
                width 20px
                height 20px
                text-align center
                line-height 20px
                top 15px
                left -10px
                color #fff
                border-radius 50%
                font-size 12px
            .btns
              color #F89407
              cursor pointer
.time-dialog
  .btns-wrap
    display flex
    margin-top 45px
    .btns
      margin-left auto
      span
        display inline-block
        width 88px
        height 36px
        line-height 36px
        text-align center
        color #fff
        border-radius 4px
        cursor pointer
        &.sure
          background-color #F89407
          &:hover
            background-color #DB8307
        &.cancal
          background-color #373A43
          margin-left 24px
.brand-investment-wrap
  .input-wrap
    display flex
    align-items center
    .title
      width 80px
      color #fff
      font-size 14px
  .btns-wrap
    display flex
    margin-top 75px
    .btns
      margin-left auto
      span
        display inline-block
        width 88px
        height 36px
        line-height 36px
        text-align center
        color #fff
        border-radius 4px
        cursor pointer
        &.sure
          background-color #F89407
          &:hover
            background-color #DB8307
        &.cancal
          background-color #373A43
          margin-left 24px
</style>
