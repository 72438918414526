<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="approval-popup-wrap"
  >
    <div class="popup-wrap">
      <i
        @click="hideTable"
        class="el-icon-close"
      ></i>
      <div class="approval-content">
        <div class="title">审批内容</div>
        <table>
          <thead>
            <tr>
              <td>序号</td>
              <td>变更类型</td>
              <td>招商品牌</td>
              <td>品牌业态</td>
              <td>落位楼宇楼层</td>
              <td>落位铺位</td>
              <td>是否首选落位</td>
              <td>跟进人</td>
              <td>计划开始时间</td>
              <td>实际开始时间</td>
              <td>计划完成时间</td>
              <td>实际完成时间</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>删除</td>
              <td>{{brandDetails.brandName}}</td>
              <td>{{brandDetails.industry}}</td>
              <td v-if="deleteList && deleteList.length > 0">{{deleteList[0].floorOfVacantBuilding || '-'}}</td>
              <td v-else>-</td>
              <td v-if="deleteList && deleteList.length > 0">{{deleteList[0].fallABunk || '-'}}</td>
              <td v-else>-</td>
              <td v-if="deleteList && deleteList.length > 0">{{deleteList[0].isBuildUpPlay || '-'}}</td>
              <td v-else>-</td>
              <td>{{brandDetails.followUserName?brandDetails.followUserName:'-'}}</td>
              <td>{{brandDetails.planStartTime ? brandDetails.planStartTime : '-'}}</td> <!-- 计划开始时间 -->
              <td>{{brandDetails.planFinishTime ? brandDetails.planFinishTime : '-'}}</td> <!-- 实际开始时间 -->
              <td>{{brandDetails.realityStartTime ? brandDetails.realityStartTime : '-'}}</td> <!-- 计划完成时间 -->
              <td>{{brandDetails.realityFinishTime ? brandDetails.realityFinishTime : '-'}}</td> <!-- 实际完成时间 -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="approval-progress">
        <div class="title">审批进度</div>
        <table>
          <thead>
            <tr>
              <td>序号</td>
              <td>节点</td>
              <td>审批人</td>
              <td>动作</td>
              <td>操作时间</td>
              <td>意见</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in approvalList"
              :key="index"
            >
              <td>{{index + 1}}</td>
              <td>{{item.nodeName ? item.nodeName : '-'}}</td>
              <td>{{item.examineUserName ? item.examineUserName : '-'}}</td>
              <td>{{item.actionName ? item.actionName : '-'}}</td>
              <td>{{item.examineTime ? item.examineTime : '-'}}</td>
              <td>{{item.examineRemark ? item.examineRemark : '-'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      approvalData: {},
      approvalList: [],
      loading: false,
      deleteList: []
    }
  },
  props: {
    projectId: {
      type: Number
    },
    taskId: {
      type: String
    },
    brandDetails: {
      type: Object
    }
  },
  mounted () {
    this.loading = true
    this.getApprovalFunc()
    window.addEventListener('click', this.closeClick)
  },
  methods: {
    closeClick (e) {
      if (e.target.className === 'approval-popup-wrap') {
        this.$emit('hideApprovalEmit')
      }
    },
    getApprovalFunc () {
      const data = {
        projectId: this.projectId,
        taskId: this.taskId
      }
      this.axios.post(api.queryFlowChartData, data).then(res => {
        this.approvalData = res.data.data
        this.approvalList = this.approvalData.data.tableList
        this.loading = false
      })
      this.axios.post(api.getdeleteapprovalsContent, {
        projectId: this.projectId,
        taskId: this.taskId
      }).then(res => {
        this.deleteList = res.data.data
      })
    },
    hideTable () {
      this.$emit('hideApprovalEmit')
    }
  }
}
</script>

<style lang="stylus" scoped>
.approval-popup-wrap
  width 100%
  height 100%
  position fixed
  left 0
  right 0
  bottom 0
  top 0
  background-color rgba(0, 0, 0, 0.8)
  z-index 111
  .popup-wrap
    position absolute
    left 50%
    top 20%
    margin-left -480px
    background-color #fff
    padding 30px
    width 1100px
    border-radius 5px
    .el-icon-close
      position absolute
      right 10px
      top 10px
      font-size 26px
      color #D9D9D9
      cursor pointer
      &:hover
        color #999
    .title
      font-size 18px
      font-weight bold
      line-height 25px
      color #333333
      margin-bottom 10px
    .approval-content, .approval-progress
      table
        width 100%
        border 1px solid #ECF1F8
        margin-bottom 20px
        thead
          tr
            td
              border 1px solid #ECF1F8
              padding 5px 8px
              font-size 14px
              color #585858
        tbody
          tr
            td
              border 1px solid #ECF1F8
              padding 6px 10px
              font-size 14px
              color #7B7B7B
      .image-wrap
        width 100%
        img
          width 100%
</style>
