<template>
  <div class="intention-berth-wrap">
    <div class="header">
      <h3>意向铺位管理</h3>
      <i
        class="el-icon-close"
        @click="close()"
      ></i>
    </div>
    <div class="content">
      <div class="brand-wrap">
        <div class="info-title">意向铺位</div>
      </div>
      <div class="brand-list">
        <div class="brand-item-info">
          <div class="brand-item">
            <div class="brand-logo">
              <img
                :src="brandDetails.brandLogo?brandDetails.brandLogo:'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                alt=""
              >
            </div>
            <div class="brand-name">
              <div
                class="name"
                @click="toDetails(brandDetails)"
              >{{brandDetails.brandName}}</div>
              <div class="industry">{{brandDetails.typeNames}} <span>{{brandDetails.brandClass}}</span> </div>
            </div>
          </div>
          <div class="berth-item">
            <div class="berth-info">
              <div class="title">意向落位铺位</div>
              <div class="berth-list">
                <div
                  class="item"
                  v-for="(list, index) in berthList"
                  :key="index"
                  :class="[list.isFirst !== 2 ? 'cannot-delete' : 'yes-delete']"
                >{{list.buildingFloor}}/{{list.berthNo}}
                  <span
                    v-if="list.isFirst === 2"
                    @click="deleteBerth(list)"
                    class="el-icon-close"
                  ></span>
                </div>
                <el-popover
                  popper-class="black-popover w-input-sty"
                  placement="top"
                  trigger="manual"
                  v-model="showPopover"
                >
                  <h3>添加意向铺位</h3>
                  <div class="height36-input add-cascader">
                    <el-cascader
                      v-model="selectFloor"
                      popper-class="black-select-panel"
                      :options="filterFloorArr"
                      @change="changeFloor"
                      style="height: 32px;"
                      ref="myCascader"
                      filterable
                      clearable
                      size="small"
                    ></el-cascader>
                  </div>
                  <div class="footer">
                    <span
                      class="button-basic button-primary"
                      :class="{'disabled-sty': selectFloor && selectFloor.length === 0}"
                      @click="selectBerth()"
                    >确定</span>
                    <span
                      class="button-basic button-low"
                      @click="addBerthClick(0)"
                    >取消</span>
                  </div>
                  <div
                    slot="reference"
                    class="icon-font icon-add2"
                    @click="addBerthClick(1)"
                  ></div>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-btn">
      <span
        :class="[isDisabled ? 'sure' : 'isDisabled']"
        @click="sure()"
      >确定</span>
      <span
        class="cancal"
        @click="close()"
      >取消</span>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      brandList: [],
      berthList: [],
      filterFloorArr: [],
      selectFloor: [],
      showPopover: false,
      selectBerthInfo: {},
      isDisabled: false,
      selectBerthId: []
    }
  },
  props: {
    brandDetails: {
      type: Object
    },
    intentionBerthList: {
      type: Array
    },
    projectId: {
      type: Number
    }
  },
  mounted () {
    this.berthList = JSON.parse(JSON.stringify(this.intentionBerthList))
    this.getFilterFloorFunc()
  },
  methods: {
    getFilterFloorFunc (id) {
      this.axios.post(api.getWholeFloorData, { projectId: this.projectId })
        .then((res) => {
          this.filterFloorArr = res.data.data
          for (const item of this.berthList) {
            this.selectBerthId.push(item.berthId)
          }
        })
    },
    changeFloor (val) {
      if (val) {
        this.isDisabled = true
        this.selectBerthInfo = this.filterFloorArr[0].children.filter(item => item.value === this.selectFloor[1])[0].children.find(x => x.value === this.selectFloor[2])
        this.selectBerthId.push(this.selectFloor[2])
      } else {
        this.selectBerthInfo = {}
      }
    },
    addBerthClick (type) {
      if (type) {
        this.showPopover = true
      } else {
        this.showPopover = false
      }
    },
    selectBerth () {
      let flag = true
      for (const item of this.berthList) {
        if (item.berthId.includes(this.selectFloor[2])) {
          flag = false
        }
      }
      if (flag) {
        const obj = {}
        obj.buildingFloor = this.selectFloor[0] + this.selectFloor[1]
        obj.berthNo = this.selectBerthInfo.label
        obj.floor = this.selectBerthInfo.label
        obj.berthId = this.selectFloor[2]
        obj.isFirst = 2
        this.berthList.push(obj)
        this.showPopover = false
        this.selectFloor = []
        this.selectBerthInfo = {}
      } else {
        this.$message({
          type: 'warning',
          message: '已有该意向落位铺位，请选择其它铺位添加！'
        })
      }
    },
    deleteBerth (item) {
      this.berthList = this.berthList.filter(options => {
        return options.berthId !== item.berthId
      })
      this.isDisabled = true
    },
    sure () {
      if (!this.isDisabled) return
      const arr = []
      const data = {}
      for (const item of this.berthList) {
        if (item.isFirst === 2) {
          const obj = {}
          obj.berthId = item.berthId
          obj.berthName = item.berthNo
          obj.building = item.buildingFloor
          obj.floor = item.floor
          arr.push(obj)
        }
      }
      data.berthIntention = arr
      data.brandId = this.brandDetails.brandId
      data.brandName = this.brandDetails.brandName
      data.gbid = this.brandDetails.gbid
      data.projectId = this.projectId
      this.axios.post(api.intentionOfThisUnit, data)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功!'
            })
            this.$emit('hideIntentionEmit', true)
          }
        })
    },
    close () {
      this.$emit('hideIntentionEmit')
    }
  }
}
</script>

<style lang="stylus" scoped>
.info-btn
  position absolute
  bottom 0
  left 0
  width 100%
  height 76px
  border-top 1px solid #373A43
  padding-top 20px
  padding-left 310px
  background #272930
  span
    display inline-block
    width 130px
    height 36px
    line-height 36px
    text-align center
    color #fff
    background #F89407
    border-radius 4px
    margin-right 28px
    cursor pointer
    &.sure
      background-color #f89407
      color #fff
      &:hover
        background-color #DB8307
    &.cancal
      background-color #373A43
    &.isDisabled
      background-color #81591b
      color rgba(255, 255, 255, 0.5)
.info-title
  position relative
  font-size 16px
  padding-left 7px
  color #fff
  &:after
    content ''
    display inline-block
    position absolute
    left 0px
    top 5px
    width 2px
    height 14px
    background #F89407
    z-index 11
.intention-berth-wrap
  .header
    display flex
    height 70px
    align-items center
    background-color #32343B
    padding 0 24px
    h3
      font-size 16px
      color #fff
      font-weight 800
    .el-icon-close
      margin-left auto
      color #fff
.content
  padding 32px 24px 60px 24px
  margin-bottom 160px
  height calc(100% - 190px)
  max-height 800px
  overflow-y auto
  .info-title
    margin-bottom 16px
  .brand-list
    .brand-item-info
      background-color #1E1E23
      margin-bottom 24px
      .brand-item
        height 89px
        display flex
        align-items center
        .brand-logo
          width 57px
          height 57px
          margin 0 12px 0 24px
          img
            width 100%
            height 100%
            border-radius 2px
        .brand-name
          min-width 220px
          .name
            font-size 16px
            font-weight bold
            color #FFFFFF
            margin-bottom 10px
            text-decoration underline
            cursor pointer
            &:hover
              color #F89407
          .industry
            margin-top 10px
            font-size 12px
            font-weight 400
            color #B2B3BD
            span
              margin-left 20px
        .font-btn
          margin-left auto
          margin-right 24px
          cursor pointer
      .berth-item
        border-top 1px solid #373A43
        min-height 86px
        .berth-info
          padding 24px 24px 1px 24px
          display flex
          .title
            font-size 14px
            font-weight 400
            color #B2B3BD
            line-height 36px
            width 100px
          .berth-list
            display flex
            flex-wrap wrap
            .item
              margin-left 24px
              position relative
              margin-bottom 24px
              &.cannot-delete
                padding 0 8px
                height 36px
                line-height 34px
                border 1px solid #F89407
                text-align center
                border-radius 2px
                font-size 12px
                font-weight 500
                color #F89407
              &.yes-delete
                padding 0 35px 0 10px
                height 36px
                line-height 36px
                background #373A43
                border-radius 2px
                font-size 12px
                font-weight 500
                color #fff
              .el-icon-close
                position absolute
                right 10px
                top 13px
            .icon-add2
              height 36px
              width 36px
              line-height 36px
              text-align center
              background-color #373A43
              margin-left 24px
              cursor pointer
              color #fff
</style>
