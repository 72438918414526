<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="approval-popup-wrap"
  >
    <div class="popup-wrap">
      <i
        @click="hideTable"
        class="el-icon-close"
      ></i>
      <div class="approval-content">
        <div class="title">审批内容</div>
        <table>
          <thead>
            <tr>
              <td>序号</td>
              <td>变更类型</td>
              <td>跟进人(原)</td>
              <td>跟进人(新)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{{brandDetails.changeType}}</td>
              <td>{{brandDetails.followUpPeople}}</td>
              <td>{{brandDetails.followNewPeople}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="approval-progress">
        <div class="title">审批进度</div>
        <table>
          <thead>
            <tr>
              <td>序号</td>
              <td>节点</td>
              <td>审批人</td>
              <td>动作</td>
              <td>操作时间</td>
              <td>意见</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in approvalList"
              :key="index"
            >
              <td>{{index + 1}}</td>
              <td>{{item.nodeName ? item.nodeName : '-'}}</td>
              <td>{{item.examineUserName ? item.examineUserName : '-'}}</td>
              <td>{{item.actionName ? item.actionName : '-'}}</td>
              <td>{{item.examineTime ? item.examineTime : '-'}}</td>
              <td>{{item.examineRemark ? item.examineRemark : '-'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      approvalData: {},
      approvalList: [],
      brandDetails: {},
      loading: false
    }
  },
  props: {
    projectId: {
      type: Number
    },
    taskId: {
      type: String
    }
  },
  mounted () {
    console.log(this.projectId, this.taskId)
    this.loading = true
    this.getApprovalFunc()
    this.getBrandFollowFunc()
    window.addEventListener('click', this.closeClick)
  },
  methods: {
    closeClick (e) {
      if (e.target.className === 'approval-popup-wrap') {
        this.$emit('hideApprovalEmit')
      }
    },
    getBrandFollowFunc () {
      this.axios.post(api.selectUpdateTblTaskContent, { taskId: this.taskId })
        .then((res) => {
          console.log(res)
          if (res.data.data && res.data.data.length) {
            this.brandDetails = res.data.data[0]
          }
        })
    },
    getApprovalFunc () {
      const data = {
        projectId: this.projectId,
        taskId: this.taskId
      }
      this.axios.post(api.queryFlowChartData, data).then(res => {
        this.approvalData = res.data.data
        this.approvalList = this.approvalData.data.tableList
        this.loading = false
      })
    },
    hideTable () {
      this.$emit('hideApprovalEmit')
    }
  }
}
</script>

<style lang="stylus" scoped>
.approval-popup-wrap
  width 100%
  height 100%
  position fixed
  left 0
  right 0
  bottom 0
  top 0
  background-color rgba(0, 0, 0, 0.8)
  z-index 111
  .popup-wrap
    position absolute
    left 50%
    top 20%
    margin-left -480px
    background-color #fff
    padding 30px
    width 900px
    border-radius 5px
    .el-icon-close
      position absolute
      right 10px
      top 10px
      font-size 26px
      color #D9D9D9
      cursor pointer
      &:hover
        color #999
    .title
      font-size 18px
      font-weight bold
      line-height 25px
      color #333333
      margin-bottom 10px
    .approval-content, .approval-progress
      table
        width 100%
        border 1px solid #ECF1F8
        margin-bottom 20px
        thead
          tr
            td
              border 1px solid #ECF1F8
              padding 5px 8px
              font-size 14px
              color #585858
        tbody
          tr
            td
              border 1px solid #ECF1F8
              padding 6px 10px
              font-size 14px
              color #7B7B7B
      .image-wrap
        width 100%
        img
          width 100%
</style>
